function gtag_report_conversion(url) {
  var callback = function () {
    if (typeof(url) != 'undefined') {
      window.location = url;
    }
  };
  window.gtag('event', 'conversion', {
      'send_to': 'AW-796011703/0WWSCMSKz4YBELfZyPsC',
      'event_callback': callback
  });
  return false;
}

window.gtag_report_conversion = gtag_report_conversion
