let Rails = require('rails-ujs')
global.Rails = Rails
import 'ekko-lightbox'
import 'owl.carousel2/dist/assets/owl.carousel.css'
import $ from 'jquery';
global.$ = $
import 'popper.js'
import 'bootstrap'
import 'owl.carousel2';
import 'jquery.counterup'
const WP = require('waypoints/lib/jquery.waypoints.js')
import WOW from 'wow.js'
import SmoothScroll from 'smooth-scroll'
const Yandex = require('yametrika.js')
const Google = require('google.js')

function calculateNavOpacity() {
  var depth = 150.0;
  var op = (depth - $(window).scrollTop()) / depth;
  if (op > 1.0) {
    op = 1.0;
  } else if (op < 0.0) {
    op = 0.0;
  }
  $(".navbar.extra").css("background-color", "rgba(31, 37, 60, " + (1 - op)*0.9 + ")");
  $(".navbar.extra").css("box-shadow", "0px 1px 10px rgba(0, 0, 0, " + (1 - op)/2*0.9 + ")");
}

function checkFormValidity(form, ev) {
  if (form.checkValidity() === false) {
    $(form).addClass('was-validated');
    ev.preventDefault();
    ev.stopPropagation();
    return false;
  } else {
    $(form).removeClass('was-validated');
  }
}

function checkFooterBottomed() {
  if ($("#footer-whitespace").length > 0) {
    ht = $("#footer-whitespace").height() - $("footer").outerHeight() - $("footer").offset().top + $(window).height();
    if (ht >= 0) {
      $("#footer-whitespace").css({height: ht});
    }
  } else {
    if ($("footer").offset().top + $("footer").outerHeight() < $(window).height()) {
      $("footer").before('<section id="footer-whitespace" style="height: ' + ($(window).height() - $("footer").outerHeight() - $("footer").offset().top) + 'px"></section>');
    }
  }
}

function manageBackToTop() {
  if ($(this).scrollTop() > 200) {
    $('.back-to-top').fadeIn(400);
  } else {
    $('.back-to-top').fadeOut(400);
  }
}

window.page_loaded_ts = Date.now()
window.page_track = []

window.onmousemove = function(event) {
  window.page_track.push([Date.now() - window.page_loaded_ts, 'mm', event.clientX, event.clientY])
}

window.onkeydown = function(event) {
  window.page_track.push([Date.now() - window.page_loaded_ts, 'kd', event.keyCode, event.keyIdentifier])
}

window.onscroll = function(event) {
  window.page_track.push([Date.now() - window.page_loaded_ts, 'sc', window.scrollY])
}

window.onclick = function(event) {
  window.page_track.push([Date.now() - window.page_loaded_ts, 'cl', event.clientX, event.clientY])
}

//$(document).on('turbolinks:load', function() {
$(document).ready( function () {
  Rails.start()

  $('form[data-trackable="true"]').on('ajax:before', function(event, xhr, settings) {
    if ($(this).find('input[data-track]')) {
      $(this).find('input[data-track]').val(JSON.stringify(window.page_track))
    }
  })
  
  $('form[data-trackable="true"]').on('ajax:complete', function(event, xhr, settings) {
    if($(this).find('input[data-track]')) {
      $(this).find('input[data-track]').val(JSON.stringify(window.page_track))
    }
  })

  //window.yaCounter49456102.hit(location.href, $('title').html(), window.turbolinks_referer);
  
  //Google
  window.dataLayer = window.dataLayer || [];
  window.gtag = function (){dataLayer.push(arguments);}
  window.gtag('js', new Date());
  window.gtag('config', 'AW-796011703');

  //Navbar
  if ($(".navbar.extra").length > 0) {
    $(window).on('scroll', calculateNavOpacity);
  }
  $(window).on('scroll', manageBackToTop);
  $(window).on('resize', checkFooterBottomed);
  calculateNavOpacity();

  /* ==========================================================================
   Back Top Link
   ========================================================================== */
  $('.back-to-top').click(function(event) {
    event.preventDefault();
    $('html, body').animate({
      scrollTop: 0
    }, 600);
    return false;
  })

  new WOW().init()

  var cntrs = $('.counter');
  if (cntrs.length > 0 && typeof(cntrs.counterUp) === 'function') {
    cntrs.counterUp({
      delay: 2,
      time: 250
    });
  }

  if (typeof($('nav').onePageNav) == 'function') {
    $('nav').onePageNav({
      currentClass: 'active'
    });
  }
  var scroll = new SmoothScroll('a[href*="#"]:not(a[href="#"])', {ignore: '.nav-link'});
  var anchor = null;
  var stripped_url = document.location.toString().split("#");
  if (stripped_url.length > 1) {
    anchor = $("#" + stripped_url[1]);
    if (anchor.length > 0) {
      scroll.animateScroll($(anchor[0]));
    }
  }

  $('form[data-remote!="true"].needs-validation').on('submit', function(ev) {
    $('.btn').attr('disabled', 'disabled');
    if (checkFormValidity(this, ev) === false) {
      $('.btn').removeAttr('disabled');
      return false;
    }
  });
  $('form[data-remote="true"].needs-validation').on('ajax:before', function(ev) {
    $('.btn').attr('disabled', 'disabled');
    if (checkFormValidity(this, ev) === false) {
      $('.btn').removeAttr('disabled');
      return false;
    }
  }).on('ajax:complete', function(ev) {
    $('.btn').removeAttr('disabled');
  });

  $('[data-toggle="lightbox"]').on('click', function(ev) {
    ev.preventDefault();
    $(this).ekkoLightbox();
  });

  checkFooterBottomed();
  
  $.each(onLoadCallbacks, function(i, cb) {
    cb()
  })
});
